import { rdrRequests, authorizedRDRequests } from "../httpRequest";
import { formatDate } from "../../util/util";

const unitGridApiRequest = {
    getFacilityDetailById: (facilityId: number) => rdrRequests.get(`fd/facilities/${facilityId}`),
    getUnitGridData: (requestData: any) => rdrRequests.post(`search/grid`, requestData),
    getUnitDetail: (unitId: number, startDate: string, apiDateFormat: string, customerId: number) => rdrRequests.get(`search/details/${unitId}/startdate/${formatDate(startDate, apiDateFormat)}` + (customerId ? `/${customerId}` : "")),
    getUnitAvailability: (unitId: number, startDate: string, nights: number, apiDateFormat: string) => rdrRequests.get(`fd/availability/getbyunit/${unitId}/startdate/${formatDate(startDate, apiDateFormat)}/nights/${nights}/true`),
    getOpenCampingFacility: (facilityId: number, startDate: string, apiDateFormat: string) => rdrRequests.get(`search/occupancy/${facilityId}/startdate/${formatDate(startDate, apiDateFormat)}`),
    markUnitAsFavourite: (customerId: number, unitId: number) => rdrRequests.get(`search/unitfavorite/${customerId}/${unitId}`),
    markUnitAsUnFavourite: (customerId: number, unitId: number) => rdrRequests.get(`search/unitunfavorite/${customerId}/${unitId}`),
    getDateRestriction: (facilityId: number, furthestDefaultCampsitesId: string) => rdrRequests.get(`fd/restrictions/startend/facility/${facilityId}/category/${furthestDefaultCampsitesId}/unittype/0`),
    getTimeBaseUnitGridData: (requestData: any) => rdrRequests.post(`search/grid`, requestData),
    checkRestractionAndLockforBooking: (requestData: any) => authorizedRDRequests.post(`webaccessfacility/check/restractionandlockforbooking`, requestData),
    getUnNumberUnitId: (startDate: string, nights: number, facilityId: number) => rdrRequests.get(`search/next/${facilityId}/startdate/${startDate}/nights/${nights}`)
}

export default unitGridApiRequest;