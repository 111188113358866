import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import passesApiRequest from "../../app/api/passes/passesApiRequest";
import { hidePreLoader, showContentLoader, hideContentLoader, showPreLoader } from "../loader/loaderSlice";
import { mapLoginInformation } from "../common/authenticationSlice";
import { WebDayUsePassResponse } from "../../app/models/pass/passes";
import navigationService from "../../app/history/history";
import { store } from "../../app/store/configureStore";
import { setShoppingCartTimer } from "../common/timerSlice";
import { onItemAddInCart } from "../customers/shoppingCartSlice";

interface SettingsState {
    passHolderNames: any,
    listOfPasses: any,
    dayUsePassDetail: WebDayUsePassResponse | null,
    customerAddressDetails: any,
    dayUsePassDetailOnChange: any,
    selectedPassDetails: any
}

const initialState: SettingsState = {
    passHolderNames: null,
    listOfPasses: null,
    dayUsePassDetail: null,
    customerAddressDetails: null,
    dayUsePassDetailOnChange: null,
    selectedPassDetails: null
}

export const getListOfPasses = createAsyncThunk<any, { selectedCustomerId: number }>(
    'Pass/GetListOfPassFromPassHolder',
    async ({ selectedCustomerId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const listOfPasses = await passesApiRequest.getListOfPasses(selectedCustomerId);
            thunkAPI.dispatch(hidePreLoader());
            return listOfPasses;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getPassHolderNames = createAsyncThunk<any>(
    'Pass/GetPassHolderNames',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const listOfPassHolders = await passesApiRequest.getPassHolderNames();
            thunkAPI.dispatch(hideContentLoader());
            return listOfPassHolders;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)


export const getDayUsePasses = createAsyncThunk<WebDayUsePassResponse, { requestData: any }>(
    'Pass/getDayUsePasses',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const dayUsePassData = await passesApiRequest.getDayUsePassDetails(requestData);
            thunkAPI.dispatch(mapLoginInformation({ customerLoginData: dayUsePassData.GuestCustomerResponse }));
            return dayUsePassData;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)


export const getDayUsePassDetailOnPlaceChange = createAsyncThunk<any, { requestData: any }>(
    'Pass/getDayUsePassDetailOnPlaceChange',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            thunkAPI.dispatch(setDayUsePassDetailOnPlaceChange(null));
            const dayUsePassDataOnChange = await passesApiRequest.getDayUsePassDetailOnPlaceChange(requestData);
            thunkAPI.dispatch(hideContentLoader());
            return dayUsePassDataOnChange;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getLoggedInCustomerAddress = createAsyncThunk<any>(
    'Pass/getLoggedInCutomerAddress',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const customerAddress = await passesApiRequest.getCustomerAddressDetail();
            thunkAPI.dispatch(hideContentLoader());
            return customerAddress;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const addToPassInCart = createAsyncThunk<any, { requestData: any }>(
    'Pass/getaddToPassInCart',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const passResult = await passesApiRequest.addToPassInCart(requestData.membershipData);

            if (passResult.IsSuccess) {
                const isSuccess = onItemAddInCart(thunkAPI.dispatch, passResult, null);
                if (isSuccess) {
                    navigationService.navigation("/Customers/ShoppingCart");
                }
                else {
                    thunkAPI.dispatch(hidePreLoader());
                }
            } else {
                thunkAPI.dispatch(hidePreLoader());
            }

            thunkAPI.dispatch(hidePreLoader());
            return passResult;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getSelectedPassDetails = createAsyncThunk<any, { passId: number }>(
    'Pass/getSelectedPassDetails',
    async ({ passId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const passDetails = await passesApiRequest.getSelectedPassDetails(passId);
            thunkAPI.dispatch(hideContentLoader());
            return passDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const processPassesCartDetails = createAsyncThunk<any, { passesParameters: any }>(
    'Pass/processPassesCartDetails',
    async ({ passesParameters }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const passResult = await passesApiRequest.processPassesCartDetails(passesParameters);
            if (passResult.IsSuccess) {
                localStorage.setItem('cart', JSON.stringify(passResult));
                localStorage.setItem('shoppingCartKey', passResult.ShoppingCartKey);
                navigationService.navigation("/Customers/ShoppingCart");
                thunkAPI.dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: false, shoppingCartTimerStartTime: null, shoppingCartTimerEndTime: null }));
                setTimeout(() => {
                    thunkAPI.dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: true, shoppingCartTimerStartTime: passResult.StartTime, shoppingCartTimerEndTime: passResult.EndTime }));
                }, 0);
            } else {
                thunkAPI.dispatch(hidePreLoader());
            }

            thunkAPI.dispatch(hidePreLoader());
            return passResult;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const maxPassLimitAndPromoCode = createAsyncThunk<any, { requestParameters: any }>(
    'Pass/maxPassLimitAndPromoCode',
    async ({ requestParameters }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const passPromoCodeResult = await passesApiRequest.maxPassLimitAndPromoCode(requestParameters);
            thunkAPI.dispatch(hidePreLoader());
            return passPromoCodeResult;
        } catch (error) {
            thunkAPI.dispatch(hidePreLoader());
        }
    }
)

export const passesSlice = createSlice({
    name: 'passes',
    initialState,
    reducers: {
        setDayUsePassDetailOnPlaceChange: (state, action) => {
            state.dayUsePassDetailOnChange = action.payload;
        },
        setSelectedPassDetail: (state, action) => {
            state.selectedPassDetails = action.payload
        }
    },
    extraReducers: (builder => {
        builder.addCase(getPassHolderNames.rejected, (state) => {
            state.passHolderNames = null;
        });
        builder.addCase(getListOfPasses.rejected, (state) => {
            state.listOfPasses = null;
        });
        builder.addCase(getLoggedInCustomerAddress.rejected, (state) => {
            state.customerAddressDetails = null;
        });
        builder.addCase(getDayUsePasses.rejected, (state) => {
            state.dayUsePassDetail = null;
        });
        builder.addCase(getDayUsePassDetailOnPlaceChange.rejected, (state) => {
            state.dayUsePassDetailOnChange = null;
        });
        builder.addCase(getSelectedPassDetails.rejected, (state) => {
            state.selectedPassDetails = null;
        });
        builder.addMatcher(isAnyOf(getDayUsePasses.fulfilled), (state, action) => {
            state.dayUsePassDetail = action.payload;
        });
        builder.addMatcher(isAnyOf(getDayUsePassDetailOnPlaceChange.fulfilled), (state, action) => {
            state.dayUsePassDetailOnChange = action.payload;
        });
        builder.addMatcher(isAnyOf(getPassHolderNames.fulfilled), (state, action) => {
            state.passHolderNames = action.payload;
        });
        builder.addMatcher(isAnyOf(getListOfPasses.fulfilled), (state, action) => {
            state.listOfPasses = action.payload;
        });
        builder.addMatcher(isAnyOf(getLoggedInCustomerAddress.fulfilled), (state, action) => {
            state.customerAddressDetails = action.payload;
        });
        builder.addMatcher(isAnyOf(getSelectedPassDetails.fulfilled), (state, action) => {
            state.selectedPassDetails = action.payload;
        });
    })
})

export const { setDayUsePassDetailOnPlaceChange, setSelectedPassDetail } = passesSlice.actions;
