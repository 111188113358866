import { createSlice } from "@reduxjs/toolkit";

interface LoaderState {
    isPreLoaderShow: boolean,
    isContentLoaderShow: boolean
}

const initialState: LoaderState = {
    isPreLoaderShow: false,
    isContentLoaderShow: false
}

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        showPreLoader: (state) => {
            state.isPreLoaderShow = true;
        },
        hidePreLoader: (state) => {
            state.isPreLoaderShow = false;
        },
        showContentLoader: (state) => {
            state.isContentLoaderShow = true;
        },
        hideContentLoader: (state) => {
            state.isContentLoaderShow = false;
        },

    },
    extraReducers: {}
})

export const { showPreLoader, hidePreLoader, showContentLoader, hideContentLoader } = loaderSlice.actions;