import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { showPreLoader, hidePreLoader, showContentLoader, hideContentLoader } from "../loader/loaderSlice";
import shoppingCartApiRequest from "../../app/api/customer/shoppingCartApiRequest";
import { setShoppingCartTimer, getShoppingCartTimerData, setShoppingCartExpireMinutes } from "../common/timerSlice";
import { setAlertMessage } from "../common/settingsSlice";
import { store } from "../../app/store/configureStore";
import navigationService from "../../app/history/history";

interface ShoppingCartState {
    shoppingCartList: any,
    otherShoppingCartDetail: any,
    cartCount: number,
    paymentSummary: any
}

const initialState: ShoppingCartState = {
    shoppingCartList: null,
    otherShoppingCartDetail: null,
    cartCount: localStorage.getItem('cartCount') && getShoppingCartTimerData().isTimerDisplay ? parseInt(localStorage.getItem('cartCount') || "0") : 0,
    paymentSummary: null
}

export const onItemAddInCart = (dispatch: any, response: any, requestData: any) => {
    if (response.IsSuccess) {
        localStorage.setItem('cart', JSON.stringify(response));
        localStorage.setItem('shoppingCartKey', response.ShoppingCartKey);
        dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: false, shoppingCartTimerStartTime: null, shoppingCartTimerEndTime: null }));
        setTimeout(() => {
            dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: true, shoppingCartTimerStartTime: response.StartTime, shoppingCartTimerEndTime: response.EndTime }));
        }, 0);
        if (requestData) {
            let shoppingCartListRequestData = {
                "shoppingCartKey": response.ShoppingCartKey
            };
            dispatch(getShoppingCartList({ requestData: shoppingCartListRequestData }));
            dispatch(getOtherShoppingCartDetail({ requestData: shoppingCartListRequestData }));
        }
        return true;
    } else {
        dispatch(setAlertMessage({ className: "loginFailpopup", header: "Message", message: response.ErrorMessage }));
        return false;
    }
}

export const getShoppingCartList = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/getShoppingCartList',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const shoppingCartList = await shoppingCartApiRequest.getShoppingCartList(requestData);
            const cartCount = shoppingCartList && shoppingCartList.CartEntry && shoppingCartList.CartEntry.$values && shoppingCartList.CartEntry.$values.length > 0 ? shoppingCartList.CartEntry.$values.length : 0;
            const shoppingCartExpireMinutes: any = shoppingCartList && shoppingCartList.CartExpireMinutes > 0 ? shoppingCartList.CartExpireMinutes : 0;
            localStorage.setItem('cartCount', cartCount);
            localStorage.setItem('shoppingCartExpireMinutes', shoppingCartExpireMinutes);
            thunkAPI.dispatch(setCartCount(cartCount));
            thunkAPI.dispatch(setShoppingCartExpireMinutes(shoppingCartExpireMinutes));
            thunkAPI.dispatch(hidePreLoader());
            return shoppingCartList;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getOtherShoppingCartDetail = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/getOtherShoppingCartDetail',
    async ({ requestData }, thunkAPI) => {
        try {
            const response = await shoppingCartApiRequest.getOtherShoppingCartDetail(requestData);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const addDonationShoppingCart = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/addDonationShoppingCart',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await shoppingCartApiRequest.addDonationShoppingCart(requestData);
            const isSuccess = onItemAddInCart(thunkAPI.dispatch, response, requestData);
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

const onEmptyCart = (dispatch: any) => {
    localStorage.removeItem("cart");
    localStorage.removeItem("shoppingCartKey");
    localStorage.removeItem("cartCount");
    dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: false, shoppingCartTimerStartTime: null, shoppingCartTimerEndTime: null }));
    dispatch(setShoppingCartList(null));
    dispatch(setCartCount(0));
    dispatch(setShoppingCartExpireMinutes(0));
}

export const emptyCart = createAsyncThunk<boolean>('shoppingCart/emptyCart',
    async (_, thunkAPI) => {
        try {
            if (localStorage.getItem("shoppingCartKey")) {
                thunkAPI.dispatch(showPreLoader());
                const response = await shoppingCartApiRequest.emptyCart({
                    "shoppingCartKey": localStorage.getItem('shoppingCartKey') ? localStorage.getItem('shoppingCartKey') : "00000000-0000-0000-0000-000000000000",
                });
                thunkAPI.dispatch(hidePreLoader());
                onEmptyCart(thunkAPI.dispatch);
            }
            return true;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const removeCartEntry = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/removeCartEntry',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const response = await shoppingCartApiRequest.removeCartEntry(requestData);
            thunkAPI.dispatch(hidePreLoader());
            let shoppingCartList = store.getState().shoppingCart.shoppingCartList;
            if (shoppingCartList && shoppingCartList.CartEntry && shoppingCartList.CartEntry.$values && shoppingCartList.CartEntry.$values.length > 1) {
                thunkAPI.dispatch(getShoppingCartList({ requestData }));
            } else {
                onEmptyCart(thunkAPI.dispatch);
            }
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const applyPromoCode = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/applyPromoCode',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await shoppingCartApiRequest.applyPromoCode(requestData);
            const isSuccess = onItemAddInCart(thunkAPI.dispatch, response, requestData);
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const extendShoppingCartTimer = createAsyncThunk<boolean>(
    'shoppingCart/extendShoppingCartTimer',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            let requestData = {
                "shoppingCartKey": localStorage.getItem("shoppingCartKey")
            }
            const response = await shoppingCartApiRequest.extendShoppingCartTimer(requestData);
            const isSuccess = onItemAddInCart(thunkAPI.dispatch, response, null);
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const CancelCartEntry = createAsyncThunk<boolean, { page: string, requestData: any }>(
    'shoppingCart/CancelCartEntry',
    async ({ page, requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            let response = null;
            if (page == "CustomerReservations") {
                response = await shoppingCartApiRequest.cancelCartEntry(requestData);
            }
            else if (page == "HcSignUps") {
                response = await shoppingCartApiRequest.cancelHCSignUp(requestData);
            }
            else if (page == "SignUps") {
                response = await shoppingCartApiRequest.cancelSignUp(requestData);
            }
            else if (page == "CustomerPasses" || page == "CustomerMemberships") {
                response = await shoppingCartApiRequest.cancelCustomerMembershipPass(requestData);
            }
            const isSuccess = onItemAddInCart(thunkAPI.dispatch, response, requestData);
            if (isSuccess) {
                localStorage.removeItem("preCartData");
                navigationService.navigation("/Customers/ShoppingCart");
            } else {
                thunkAPI.dispatch(hidePreLoader());
            }
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export async function CheckoutShoppingCart(requestData: any, callback: any) {
    const response = await shoppingCartApiRequest.checkoutShoppingCart(requestData);
    if (response) {
        callback(response);
    }
    else {
        callback(null);
    }
}

export const checkoutPaymentResponse = createAsyncThunk<boolean, { requestData: any }>(
    'shoppingCart/checkoutPaymentResponse',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const response = await shoppingCartApiRequest.checkoutPaymentResponse(requestData);
            if (response && response.IsSuccess) {
                onEmptyCart(thunkAPI.dispatch);
                navigationService.navigation("/Customers/PaymentSummary");
                thunkAPI.dispatch(setAlertMessage({ className: "", header: "Message", message: response.ConfirmationMessage }));
            }
            else {
                thunkAPI.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Message", message: response.ErrorMessage }));
            }
            thunkAPI.dispatch(hidePreLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const ModifyCartEntry = createAsyncThunk<boolean, { page: string, requestData: any }>(
    'shoppingCart/Modify',
    async ({ page, requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            let response = null;
            if (page == "CustomerReservations") {
                response = await shoppingCartApiRequest.modifyReservation(requestData);
                if(response && response.IsSuccess)
                {
                   
                }
                else
                {
                    thunkAPI.dispatch(setAlertMessage({ className: "", header: "Message", message: response.ErrorMessages }));
                }
            }
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)


export const shoppingCartSlice = createSlice({
    name: 'shoppingCart',
    initialState,
    reducers: {
        setShoppingCartList: (state, action) => {
            state.shoppingCartList = action.payload;
        },
        setCartCount: (state, action) => {
            state.cartCount = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(getShoppingCartList.rejected, (state) => {
            state.shoppingCartList = null;
        });
        builder.addCase(getOtherShoppingCartDetail.rejected, (state) => {
            state.otherShoppingCartDetail = null;
        });
        builder.addCase(checkoutPaymentResponse.rejected, (state) => {
            state.paymentSummary = null;
        });
        builder.addMatcher(isAnyOf(getShoppingCartList.fulfilled), (state, action) => {
            state.shoppingCartList = action.payload;
        });
        builder.addMatcher(isAnyOf(getOtherShoppingCartDetail.fulfilled), (state, action) => {
            state.otherShoppingCartDetail = action.payload;
        });
        builder.addMatcher(isAnyOf(checkoutPaymentResponse.fulfilled), (state, action) => {
            state.paymentSummary = action.payload;
        });
    })
})

export const { setShoppingCartList, setCartCount } = shoppingCartSlice.actions;