import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import customerApiRequest from "../../app/api/customer/customerApiRequest";
import { CustomerDetailResponse, Customer } from "../../app/models/customer";
import { store } from "../../app/store/configureStore";
import { setAlertMessage } from "../common/settingsSlice";
import { hidePreLoader, showContentLoader, hideContentLoader, showPreLoader } from "../../modules/loader/loaderSlice";
import { DependentMember, WebDependentProfileInfo, WebDependentProfileResponse } from "../../app/models/customer/dependent";
import { mapLoginInformation } from "../common/authenticationSlice";
import navigationService from "../../app/history/history";
import { openLoginModal } from "../../app/util/util";

interface CustomerState {
    customerDependent: any,
    dependentProfile: WebDependentProfileResponse | null,
    customer: Customer | null,
    waitListInfo: any,
    customerFormDetails: any,
    preCheckInDetails: any,
    customerProfileDetails: Customer | null,
    dependentDetail: WebDependentProfileInfo | null,
    houseHoldInfo: any,
    customerReservations: any,
    waiverAgreementDetail: any,
    customerPasses: any,
    customerSignUps: any,
    customerHCSignUps: any,
    customerMemberships: any,
    customerGiftPasses: any,
    paymentSummary: any
}

const initialState: CustomerState = {
    customerDependent: [],
    dependentProfile: null,
    customer: null,
    waitListInfo: [],
    customerFormDetails: null,
    preCheckInDetails: [],
    customerProfileDetails: null,
    dependentDetail: null,
    houseHoldInfo: [],
    customerReservations: null,
    waiverAgreementDetail: null,
    customerPasses: null,
    customerSignUps: null,
    customerHCSignUps: null,
    customerMemberships: null,
    customerGiftPasses: null,
    paymentSummary: null
}

export const getCustomerDependent = createAsyncThunk<DependentMember[]>(
    'customer/getCustomerDependent',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const customerDependent: DependentMember[] = await customerApiRequest.getCustomerDependent();
            thunkAPI.dispatch(hidePreLoader());
            return customerDependent;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const getCustomerFormDetails = createAsyncThunk<any>(
    'customer/getCustomerFormDetails',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const customerFormDetails = await customerApiRequest.getCustomerFormDetails();
            thunkAPI.dispatch(hidePreLoader());
            return customerFormDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)


export const getCustomerProfileDetails = createAsyncThunk<Customer>(
    'customer/getCustomerProfileDetails',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const customerProfileDetails: Customer = await customerApiRequest.getCustomerProfileDetails();
            thunkAPI.dispatch(hidePreLoader());
            return customerProfileDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDependentProfile = createAsyncThunk<WebDependentProfileResponse, { requestData: any }>(
    'customer/getDependentProfile',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const dependentProfile: WebDependentProfileResponse = await customerApiRequest.getDependentProflie(requestData);
            thunkAPI.dispatch(hidePreLoader());
            return dependentProfile;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDependentDetail = createAsyncThunk<WebDependentProfileInfo, { requestData: any }>(
    'customer/getDependentDetail',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const dependentDetail: WebDependentProfileInfo = await customerApiRequest.getDependentDetail(requestData);
            thunkAPI.dispatch(hidePreLoader());
            return dependentDetail;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveDependentProfileInfo = createAsyncThunk<WebDependentProfileInfo[], { dependentInfo: WebDependentProfileInfo }>(
    'customer/saveDependentProfileInfo',
    async ({ dependentInfo }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const saveResponse = await customerApiRequest.saveDependentProfileInfo(dependentInfo);
            thunkAPI.dispatch(hidePreLoader());
            return saveResponse;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const createCustomer = createAsyncThunk<any, { customer: Customer }>(
    'customer/createCustomer',
    async ({ customer }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            customer.DOB = customer.Year ? "01/01/" + customer.Year : "";
            customer.HasSpecialNeeds = customer.HasSpecialNeedsName === "Yes" ? true : false;
            const response = await customerApiRequest.createCustomer(customer);
            thunkAPI.dispatch(hidePreLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateCustomer = createAsyncThunk<any, { customer: Customer }>(
    'customer/updateCustomer',
    async ({ customer }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            customer.CustomerId = store.getState().authentication.customerId;
            customer.DOB = customer.Year ? "01/01/" + customer.Year : "";
            customer.HasSpecialNeeds = customer.HasSpecialNeedsName === "Yes" ? true : false;
            const response: CustomerDetailResponse = await customerApiRequest.updateCustomer(customer);
            thunkAPI.dispatch(mapLoginInformation({ customerLoginData: response }));
            thunkAPI.dispatch(hidePreLoader());
            thunkAPI.dispatch(setAlertMessage({
                className: "", header: "Message", message: store.getState().common.dynamicConfigMsg["profile_savesuccess"]
            }));
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCustomerWaitListDetail = createAsyncThunk<any>(
    'customer/getCustomerWaitListDetail',
    async (_, thunkAPI) => {
        try {
            const waitlistdetails = await customerApiRequest.getWaitListSignUp();

            if (waitlistdetails != null) {
                waitlistdetails.waitListDetail = waitlistdetails.$values;
            }
            return waitlistdetails
        }
        catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const cancelWaitList = createAsyncThunk<any, { waitlistId: any }>(
    'customer/cancelWaitList',
    async (waitlistId, thunkAPI) => {
        thunkAPI.dispatch(showContentLoader());
        try {
            let waitListInfo: any = {
                "waitlistinfo": {
                    "WaitlistId": waitlistId,
                    "customerId": 0,
                }
            };
            const waitlistdetails = await customerApiRequest.cancelWaitList(waitListInfo);
            if (waitlistdetails) {
                thunkAPI.dispatch(hideContentLoader());
                thunkAPI.dispatch(setAlertMessage({ header: "Success", message: "Successfully cancelled." }));
                let waitListInfo = { ...store.getState().customer.waitListInfo };
                let selectedFilter = Object.assign([], waitListInfo.waitListDetail);
                let index = selectedFilter.findIndex((ele: any) => { return ele.WaitlistId == waitlistId });
                if (index >= 0) {
                    selectedFilter.splice(index, 1);
                    waitListInfo.waitListDetail = selectedFilter;
                    thunkAPI.dispatch(setWaitListInfo(waitListInfo));
                }
            }
            else {
                thunkAPI.dispatch(setAlertMessage({ header: "Information", message: "Not able Process Your Reqeust." }));
            }
            return waitlistdetails
        }
        catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const getPreCheckInReservationDetail = createAsyncThunk<any, { requestData: any }>(
    'customer/getPreCheckInReservationDetail',
    async ({ requestData }, thunkAPI) => {
        try {
            let preCheckInRequest: any = {
                "ConfirmationNumber": requestData.ConfirmationNumber,
                "ReservationId": requestData.ReservationId
            };
            const Details = await customerApiRequest.preCheckInDetails(preCheckInRequest);
            if (Details) {
                let preCheckInDetails = { ...store.getState().customer.preCheckInDetails };
                preCheckInDetails = Details;
                if (Details.vehicle != null) {
                    preCheckInDetails.vehicle = Details.vehicle.$values;
                }
                if (Details.states != null) {
                    preCheckInDetails.states = Details.states.$values;
                }
                if (Details.itemSetups != null) {
                    preCheckInDetails.itemSetups = Details.itemSetups.$values;
                }
                if (Details.ReservationVehicles != null) {
                    preCheckInDetails.ReservationVehicles = Details.ReservationVehicles.$values;
                }
                thunkAPI.dispatch(setPreCheckInDetails(preCheckInDetails));
            }
        }
        catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const savePreCheckInReservation = createAsyncThunk<any, { requestData: any }>(
    'customer/savePreCheckInReservation',
    async ({ requestData }, thunkAPI) => {
        try {
            const res = await customerApiRequest.savePreCheckInReservation(requestData);
            if (res) {

            }

        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export async function rePrintByConfirmationNo(page: string, requestData: any, callback: any) {
    let res = null;
    if (page === "HcSignUps") {
        res = await customerApiRequest.rePrintHCSignUpsByConfirmation(requestData);
    } else if (page === "SignUps") {
        res = await customerApiRequest.rePrintSignUpsByConfirmation(requestData);
    } else if (page === "CustomerPasses") {
        res = await customerApiRequest.rePrintPassByConfirmation(requestData);
    } else if (page === "CustomerMemberships") {
        res = await customerApiRequest.rePrintCustomerMemberShips(requestData);
    } else if (page === "CustomerGiftPasses") {
        res = await customerApiRequest.printGiftCardReceipt(requestData);
    } else {
        res = await customerApiRequest.rePrintByConfirmationNo(requestData);
    }

    if (res && res.$values && res.$values.length > 0 && res.$values[0].Data) {
        callback(res.$values[0].Data);
    }
    else {
        callback(null);
    }
}


export const recoverPassword = createAsyncThunk<any, { email: string }>(
    'Customer/ForgotPassword',
    async ({ email }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const recoverPasswordResponse = await customerApiRequest.recoverPassword(email);
            thunkAPI.dispatch(hidePreLoader());
            (window as any).$('#btnPopupClose').click(function () {
                openLoginModal();
            });
            return recoverPasswordResponse;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getHouseHoldInfo = createAsyncThunk<any>(
    'customer/getHouseHoldInfo',
    async (_, thunkAPI) => {
        try {
            const res = await customerApiRequest.getHouseHoldInfo();
            if (res) {
                let houseHoldInfo = { ...store.getState().customer.houseHoldInfo };
                houseHoldInfo = res.$values;
                thunkAPI.dispatch(setHouseHoldInfo(houseHoldInfo));
            }

        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const getCustomerReservationCartsBySearchParams = createAsyncThunk<any, { requestData: any }>(
    'customer/getCustomerReservationCartsBySearchParams',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const customerReservations = await customerApiRequest.getCustomerReservationCartsBySearchParams(requestData);
            if ((customerReservations && customerReservations.MyReservations == null) || (customerReservations && customerReservations.MyReservations && customerReservations.MyReservations.$values && customerReservations.MyReservations.$values.length === 0)) {
                thunkAPI.dispatch(setAlertMessage({ header: "Information", message: store.getState().common.dynamicConfigMsg['reservation_nofacility'] }));
            }
            thunkAPI.dispatch(hideContentLoader());
            return customerReservations;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getWaiverAgreementDetail = createAsyncThunk<any, { requestData: any }>(
    'customer/getWaiverAgreementDetail',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const waiverAgreementDetail = await customerApiRequest.getWaiverAgreementDetail(requestData);
            thunkAPI.dispatch(hidePreLoader());
            return waiverAgreementDetail;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const submitWaiverAgreementDetail = createAsyncThunk<any, { requestData: any }>(
    'customer/submitWaiverAgreementDetail',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const waiverAgreementDetail = await customerApiRequest.submitWaiverAgreementDetail(requestData);
            thunkAPI.dispatch(hidePreLoader());
            return true;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCustomerPasses = createAsyncThunk<any, { requestData: any }>(
    'customer/getCustomerPasses',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const customerPasses = await customerApiRequest.getCustomerPasses(requestData);
            if ((customerPasses && customerPasses.MyReservations == null) || (customerPasses && customerPasses.MyReservations && customerPasses.MyReservations.$values && customerPasses.MyReservations.$values.length === 0)) {
                thunkAPI.dispatch(setAlertMessage({ header: "Information", message: store.getState().common.dynamicConfigMsg['reservation_nomembership'] }));
            }
            thunkAPI.dispatch(hideContentLoader());
            return customerPasses;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

const handleSignUpResponse = (cboRange: number) => {
    let message = "";
    let dynamicConfigMsg = store.getState().common.dynamicConfigMsg;
    if (cboRange === 0) {
        message = dynamicConfigMsg["signup_currentlyhave"] + dynamicConfigMsg["signup_activitytextplural"] + " " + dynamicConfigMsg["signup_signups"];
    } else if (cboRange === 1) {
        message = dynamicConfigMsg["signup_currentlyhave"] + dynamicConfigMsg["signup_nosignthismonth"];
    } else if (cboRange === 2) {
        message = dynamicConfigMsg["signup_currentlyhave"] + dynamicConfigMsg["signup_signlastmonth"];
    } else if (cboRange === 3) {
        message = dynamicConfigMsg["signup_nocompleted"] + dynamicConfigMsg["signup_signups"];
    } else {
        message = dynamicConfigMsg["signup_nosignactivity"];
    }
    return message;
}

export const getCustomerSignUps = createAsyncThunk<any, { requestData: any }>(
    'customer/getCustomerSignUps',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const customerSignUps = await customerApiRequest.getCustomerSignUps(requestData);
            if ((customerSignUps && customerSignUps.MyReservations == null) || (customerSignUps && customerSignUps.MyReservations && customerSignUps.MyReservations.$values && customerSignUps.MyReservations.$values.length === 0)) {
                thunkAPI.dispatch(setAlertMessage({ header: "Information", message: handleSignUpResponse(requestData.cboRange) }));
            }
            thunkAPI.dispatch(hideContentLoader());
            return customerSignUps;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCustomerHCSignUps = createAsyncThunk<any, { requestData: any }>(
    'customer/getCustomerHCSignUps',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const customerHCSignUps = await customerApiRequest.getCustomerHCSignUps(requestData);
            if ((customerHCSignUps && customerHCSignUps.MyReservations == null) || (customerHCSignUps && customerHCSignUps.MyReservations && customerHCSignUps.MyReservations.$values && customerHCSignUps.MyReservations.$values.length === 0)) {
                thunkAPI.dispatch(setAlertMessage({ header: "Information", message: handleSignUpResponse(requestData.cboRange) }));
            }
            thunkAPI.dispatch(hideContentLoader());
            return customerHCSignUps;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCustomerMemberships = createAsyncThunk<any, { requestData: any }>(
    'customer/getCustomerMemberships',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const customerMemberships = await customerApiRequest.getCustomerMemberships(requestData);
            if ((customerMemberships && customerMemberships.MyReservations == null) || (customerMemberships && customerMemberships.MyReservations && customerMemberships.MyReservations.$values && customerMemberships.MyReservations.$values.length === 0)) {
                thunkAPI.dispatch(setAlertMessage({ header: "Information", message: store.getState().common.dynamicConfigMsg['nomemberships'] }));
            }
            thunkAPI.dispatch(hideContentLoader());
            return customerMemberships;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCustomerGiftPasses = createAsyncThunk<any, { requestData: any }>(
    'customer/getCustomerGiftPasses',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const customerGiftPasses = await customerApiRequest.getCustomerGiftPasses(requestData);
            thunkAPI.dispatch(hideContentLoader());
            if (((customerGiftPasses && customerGiftPasses.GiftPassesInfo == null) || (customerGiftPasses && customerGiftPasses.GiftPassesInfo && customerGiftPasses.GiftPassesInfo.$values && customerGiftPasses.GiftPassesInfo.$values.length === 0)) && customerGiftPasses.ErrorMessage) {
                thunkAPI.dispatch(setAlertMessage({ header: "Information", message: customerGiftPasses.ErrorMessage }));
            }
            return customerGiftPasses;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateGiftPassEmailDetails = createAsyncThunk<any, { requestData: any }>(
    'customer/updateGiftPassEmailDetails',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await customerApiRequest.updateGiftPassEmailDetails(requestData);
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getGiftPassDetails = createAsyncThunk<any, { requestData: any }>(
    'customer/getGiftPassDetails',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await customerApiRequest.getGiftPassDetails(requestData);
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const verifyValidAddress = createAsyncThunk<any, { requestData: any }>(
    'customer/verifyValidAddress',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const response = await customerApiRequest.verifyValidAddress(requestData);
            thunkAPI.dispatch(hideContentLoader());
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCustomer: (state, action) => {
            state.customer = action.payload
        },
        setWaitListInfo: (state, action) => {
            state.waitListInfo = action.payload;
        },
        setPreCheckInDetails: (state, action) => {
            state.preCheckInDetails = action.payload;
        },
        setHouseHoldInfo: (state, action) => {
            state.houseHoldInfo = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(getCustomerDependent.rejected, (state) => {
            state.customerDependent = [];
        });
        builder.addCase(getCustomerFormDetails.rejected, (state) => {
            state.customerFormDetails = null;
        });
        builder.addCase(getCustomerWaitListDetail.rejected, (state) => {
            state.waitListInfo = [];
        });
        builder.addCase(getCustomerProfileDetails.rejected, (state) => {
            state.customerProfileDetails = null;
        });
        builder.addCase(getDependentDetail.rejected, (state) => {
            state.dependentDetail = null;
        });
        builder.addCase(getCustomerReservationCartsBySearchParams.rejected, (state) => {
            state.customerReservations = null;
        });
        builder.addCase(getWaiverAgreementDetail.rejected, (state) => {
            state.waiverAgreementDetail = null;
        });
        builder.addCase(getCustomerPasses.rejected, (state) => {
            state.customerPasses = null;
        });
        builder.addCase(getCustomerSignUps.rejected, (state) => {
            state.customerSignUps = null;
        });
        builder.addCase(getCustomerHCSignUps.rejected, (state) => {
            state.customerHCSignUps = null;
        });
        builder.addCase(getCustomerMemberships.rejected, (state) => {
            state.customerMemberships = null;
        });
        builder.addCase(getCustomerGiftPasses.rejected, (state) => {
            state.customerGiftPasses = null;
        });


        builder.addMatcher(isAnyOf(getPreCheckInReservationDetail.rejected), (state, action) => {
            state.preCheckInDetails = [];
        });
        builder.addMatcher(isAnyOf(getHouseHoldInfo.rejected), (state, action) => {
            state.houseHoldInfo = [];
        });
        builder.addMatcher(isAnyOf(getCustomerDependent.fulfilled), (state, action) => {
            state.customerDependent = action.payload;
        });
        builder.addMatcher(isAnyOf(getCustomerFormDetails.fulfilled), (state, action) => {
            state.customerFormDetails = action.payload;
        });
        builder.addMatcher(isAnyOf(getDependentProfile.fulfilled), (state, action) => {
            state.dependentProfile = action.payload;
        });
        builder.addMatcher(isAnyOf(getDependentDetail.fulfilled), (state, action) => {
            state.dependentDetail = action.payload;
        });
        builder.addMatcher(isAnyOf(getCustomerWaitListDetail.fulfilled), (state, action) => {
            state.waitListInfo = action.payload;
        });
        builder.addMatcher(isAnyOf(getCustomerProfileDetails.fulfilled), (state, action) => {
            state.customerProfileDetails = action.payload;
        })
        builder.addMatcher(isAnyOf(getCustomerReservationCartsBySearchParams.fulfilled), (state, action) => {
            state.customerReservations = action.payload;
        })
        builder.addMatcher(isAnyOf(getWaiverAgreementDetail.fulfilled), (state, action) => {
            state.waiverAgreementDetail = action.payload;
        })
        builder.addMatcher(isAnyOf(getCustomerPasses.fulfilled), (state, action) => {
            state.customerPasses = action.payload;
        })
        builder.addMatcher(isAnyOf(getCustomerSignUps.fulfilled), (state, action) => {
            state.customerSignUps = action.payload;
        })
        builder.addMatcher(isAnyOf(getCustomerHCSignUps.fulfilled), (state, action) => {
            state.customerHCSignUps = action.payload;
        })
        builder.addMatcher(isAnyOf(getCustomerMemberships.fulfilled), (state, action) => {
            state.customerMemberships = action.payload;
        })
        builder.addMatcher(isAnyOf(getCustomerGiftPasses.fulfilled), (state, action) => {
            state.customerGiftPasses = action.payload;
        })
    })
})

export const { setCustomer, setWaitListInfo, setPreCheckInDetails, setHouseHoldInfo } = customerSlice.actions;