import { rdrRequests, rdRequests, authorizedRDRequests } from "../httpRequest";

const settingsRequest = {
    loadEnterprise: (domain: string) => rdRequests.get(`webaccesscustomer/load/enterprise?domainUrl=${domain}`),
    getWebMenu: () => rdrRequests.get('search/popular/WebMenu'),
    getWebSiteSettings: () => rdrRequests.get('enterprise/websitesettings'),
    getConfigMessage: () => rdrRequests.get('search/popular/DynamicConfigMessage'),
    getEnterpriseSetting: () => rdrRequests.get('enterprise/settings'),
    getFutureBookingStartsEndsDates: () => rdRequests.post('webaccessfacility/futurebookingstartsendsdates', {}),
    getWebSecuritySettings: () => rdRequests.get(`webaccesscustomer/load/websitesecuritysettings`),
    getContactUsSubject: () => rdRequests.get(`webaccesscustomer/ContactUsSubjects`),
    submitContactUs: (requestData: any) => rdRequests.post('webaccesscustomer/Send/ContactUsEmail', requestData),
    checkAuthorizeLogin: () => authorizedRDRequests.post('webaccesscustomer/check/authorizelogin', {})
}

export default settingsRequest;