import React, { useEffect, useState, lazy, Suspense } from 'react';
import setColorsFromThemeJsonTS from './app/layout/css/theme';
import { setRDBaseURL, setRDRBaseURL } from './app/api/httpRequest';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/store/configureStore";
import './app/layout/css/bootstrap.css';
import './app/layout/css/style.css';
import { setApplicationSettings, loadEnterprise, setEnterpriseDetail } from "./modules/common/settingsSlice";
import { decryptInstallationIdentity } from "./app/util/util";
import { PreLoader } from './modules/loader/Loader';
const RouteComponent = lazy(() => import('./modules/Route'));

const LocationWrapper = ({ children }: any) => {
  const location = useLocation();
  const removePreCartData = () => {
    if (!location.pathname.includes("SelectReservationPreCart")) {
      localStorage.removeItem("preCartData");
    }
  }
  const removeSignPreCartData = () => {
    if (!location.pathname.includes("SelectSignUpPreCart") && !location.pathname.includes("Details")&& !location.pathname.includes("TellAFriend")) {
      localStorage.removeItem("ActivitySignUp");
      localStorage.removeItem("ActivitySessionID");
    }
  }
  useEffect(() => {
    removePreCartData()
    removeSignPreCartData()
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    removePreCartData();
    removeSignPreCartData();
  }, [location.pathname]);
  return children
}

function App() {
  const [baseName, setBaseName] = useState("");
  const dispatch = useAppDispatch();
  const common = useAppSelector(state => state.common);

  useEffect(() => {
    fetch(`config.json`)
      .then(response => response.json())
      .then(data => {
        setRDRBaseURL(data.rdrApiUrl);
        setRDBaseURL(data.rdApiUrl);
        data.imageBaseUrl = data.isCdnUrlActive ? data.cdnUrl : "";
        loadEnterprise((enterprise: any) => {          
          if (enterprise) {
            setColorsFromThemeJsonTS(enterprise.enterpriseName);
            data.installIdentity = decryptInstallationIdentity(enterprise.installIdentity);
            dispatch(setApplicationSettings(data));
            dispatch(setEnterpriseDetail(enterprise));
          }
        });
      })
    setTimeout(() => {
      setBaseName((window as any).instanceName);
    }, 0);
  }, []);

  return (
    <Suspense fallback={<PreLoader isPreLoaderShow={true}/>}>
      {/* <ErrorBoundary> */}
      <Router basename={baseName}>
        <LocationWrapper>
          {common.applicationSettings && common.enterpriseDetail ? <RouteComponent /> : <React.Fragment></React.Fragment>}
        </LocationWrapper>
      </Router>
      {/* </ErrorBoundary> */}
    </Suspense>
  );
}

export default App;
