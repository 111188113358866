import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { settingsSlice } from "../../modules/common/settingsSlice";
import { loaderSlice } from "../../modules/loader/loaderSlice";
import { authenticationSlice } from "../../modules/common/authenticationSlice";
import { searchSlice } from "../../modules/facilities/components/search/searchSlice";
import { parksSlice } from "../../modules/facilities/components/parks/parksSlice";
import { facilitiesSlice } from "../../modules/facilities/components/facilities/facilitiesSlice";
import { unitGridSlice } from "../../modules/facilities/components/unitGrid/unitGridSlice";
import { drawSlice } from "../../modules/facilities/components/draw/drawSlice";
import { facilityPreCartSlice } from "../../modules/facilities/components/facilityPreCart/facilityPreCartSlice";
import { customerSlice } from "../../modules/customers/customerSlice";
import { webCheckInSlice } from "../../modules/webcheckin/webCheckInSlice";
import { activitySlice } from "../../modules/activities/activitySlice";
import { timerSlice } from "../../modules/common/timerSlice";
import { shoppingCartSlice } from "../../modules/customers/shoppingCartSlice";
import { membershipSlice } from "../../modules/memberships/membershipSlice";
import { passesSlice } from "../../modules/passes/passesSlice";
import { merchandiseSlice } from "../../modules/merchandise/merchandiseSlice";

export const store = configureStore({
    reducer: {
        common: settingsSlice.reducer,
        loader: loaderSlice.reducer,
        authentication: authenticationSlice.reducer,
        search: searchSlice.reducer,
        parks: parksSlice.reducer,
        facilities: facilitiesSlice.reducer,
        unitGrid: unitGridSlice.reducer,
        draw: drawSlice.reducer,
        customer: customerSlice.reducer,
        facilityPreCart: facilityPreCartSlice.reducer,
        activity: activitySlice.reducer,
        webCheckIn: webCheckInSlice.reducer,
        timer: timerSlice.reducer,
        shoppingCart: shoppingCartSlice.reducer,
        membership: membershipSlice.reducer,
        passes: passesSlice.reducer,
        merchandise: merchandiseSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;