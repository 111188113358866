import axios, { AxiosResponse } from "axios";
import { store } from "../store/configureStore";
import { setAlertMessage } from "../../modules/common/settingsSlice";
import { customerLogOut } from "../../modules/common/authenticationSlice";

const rdAxios = axios.create({
    baseURL: ''
});

const authorizedRDAxios = axios.create({
    baseURL: ''
});

const rdrAxios = axios.create({
    baseURL: ''
});

export const setRDBaseURL = (baseURL: string) => {
    rdAxios.defaults.baseURL = baseURL;
    authorizedRDAxios.defaults.baseURL = baseURL;
}

export const setRDRBaseURL = (baseURL: string) => {
    rdrAxios.defaults.baseURL = baseURL;
}

const responseBody = (response: AxiosResponse) => response.data;

const handleErrorResponse = (error: any) => {
    if (error.code === "ERR_CANCELED") {
        return Promise.reject(error);
    } else {
        if (error.response && error.response.status === 401) {
            if (error.response.status === 401) {
                store.dispatch(customerLogOut({ isOpenLogin: true }));
            } else {
                store.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Error", message: "" }));
                return Promise.reject(error);
            }
        } else {
            store.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Error", message: "" }));
            return Promise.reject(error);
        }
    }
}

const handleRDResponse = (response: any) => {
    if (response.data) {
        if (response.data.Response === 1 || response.data.Response === 4) {
            if (response.data.Response === 4) {
                store.dispatch(setAlertMessage({ className: "", header: "Success", message: response.data.Message }));
            }
            response.data = response.data.Result;
            return response;
        }
        else {
            let message = response.data.Response === 3 ? response.data.ExceptionString : response.data.Message;
            store.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Error", message: message }));
            return Promise.reject(response);
        }
    }
    return response;
}

rdrAxios.interceptors.response.use((response: any) => {
    return response;
}, (error) => {
    return handleErrorResponse(error);
});

rdAxios.interceptors.response.use((response: any) => {
    return handleRDResponse(response);
}, (error) => {
    return handleErrorResponse(error);
});

rdAxios.interceptors.request.use((config: any) => {   
    const installIdentity = store.getState().common.applicationSettings?.installIdentity;
    config.headers.InstallationsIdentity = installIdentity ? installIdentity : "";
    config.headers.accessToken = store.getState().authentication.ssoAccessToken;
    return config;
});

authorizedRDAxios.interceptors.response.use((response: any) => {
    return handleRDResponse(response);
}, (error) => {
    return handleErrorResponse(error);
});

authorizedRDAxios.interceptors.request.use((config: any) => {
    const accessToken = store.getState().authentication.accessToken;
    const installIdentity = store.getState().common.applicationSettings?.installIdentity;
    config.headers.InstallationsIdentity = installIdentity ? installIdentity : "";
    config.headers.accessToken = store.getState().authentication.ssoAccessToken;
    if (accessToken) {
        config.headers['Content-Type'] = 'application/json';
        config.headers.Authorization = 'Bearer ' + accessToken;
        return config;
    } else {
        store.dispatch(customerLogOut({ isOpenLogin: true }));
        return {
            ...config,
            cancelToken: new axios.CancelToken((cancel) => cancel('Cancel repeated request'))
        };
    }
});

export const rdRequests = {
    get: (url: string, params?: URLSearchParams) => rdAxios.get(url, { params }).then(responseBody),
    post: (url: string, body: {}) => rdAxios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => rdAxios.put(url, body).then(responseBody),
    delete: (url: string) => rdAxios.delete(url).then(responseBody),
}

export const authorizedRDRequests = {
    get: (url: string, params?: URLSearchParams) => authorizedRDAxios.get(url, { params }).then(responseBody),
    post: (url: string, body: {}) => authorizedRDAxios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => authorizedRDAxios.put(url, body).then(responseBody),
    delete: (url: string) => authorizedRDAxios.delete(url).then(responseBody)
}

export const rdrRequests = {
    get: (url: string, params?: URLSearchParams) => rdrAxios.get(url, { params }).then(responseBody),
    post: (url: string, body: {}) => rdrAxios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => rdrAxios.put(url, body).then(responseBody),
    delete: (url: string) => rdrAxios.delete(url).then(responseBody)
}