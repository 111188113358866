import { authorizedRDRequests, rdRequests } from "../httpRequest";

const passesApiRequest = {
  getPassHolderNames: () => rdRequests.get(`Pass/GetPassHolderNames`),
  getListOfPasses: (selectedCustomerId: number) => rdRequests.get(`Pass/GetListOfPassFromPassHolder?SelectedCustomerId=${selectedCustomerId}`),
  getCustomerAddressDetail: () => authorizedRDRequests.post(`Pass/CustomerAddressDetail`,{}),
  getDayUsePassDetails: (requestData: any) => rdRequests.post(`Pass/load/dayusepassdetail`, requestData),
  getDayUsePassDetailOnPlaceChange: (requestData: any) => rdRequests.post(`Pass/dayusepassbyplaceid`, requestData),
  getIsZipCodeValid: (zipCode: string | undefined) => rdRequests.get(`Pass/IsValidZipCode?zipCode=${zipCode}`),
  addToPassInCart: (requestData: any) => authorizedRDRequests.post(`Pass/MembershipAddToCart`, requestData),
  getSelectedPassDetails: ( passId: number) => rdRequests.get(`Pass/GetSelectedPassInformation?passId=${passId}`),
  processPassesCartDetails: (passesParameters: any) => authorizedRDRequests.post(`Pass/ValidatePassesCartDetails`, passesParameters),
  maxPassLimitAndPromoCode: (passesParameters: any) => authorizedRDRequests.post(`Pass/CheckPassLimitAndPromoCode`, passesParameters)
}

export default passesApiRequest;